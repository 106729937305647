import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CognitoUser } from '@app/services/auth-cognito.service';
import { AppConfig } from '@app/shared/utils/config/app-config';
import { AccessRights, Roles, User } from '@app/models/user';

@Injectable({
	providedIn: 'root'
})
export class UserService {
	private user$: BehaviorSubject<User> = new BehaviorSubject<User>(null);

	setUserFromCognito(cognitoUser: CognitoUser) {
		const roles: Roles[] = this.getRoleFromCognitoGroups(cognitoUser.attributes['custom:groups']);
		const accessRights: AccessRights[] = Array.from(this.createAccessBy(roles));
		const user: User = {
			access: accessRights,
			roles: roles,
			id: cognitoUser.attributes.sub,
			username: cognitoUser.username,
			email: cognitoUser.attributes.email,
			firstName: cognitoUser.attributes.given_name,
			lastName: cognitoUser.attributes.family_name,
			accountName: cognitoUser.attributes['custom:samaccountname']
		};

		this.user$.next(user);
	}

	getUser() {
		return this.user$.value;
	}

	hasAccess(access: AccessRights) {
		return this.getUser().access.includes(access);
	}

	hasRole(role: Roles) {
		return this.getUser()?.roles.includes(role);
	}

	private getRoleFromCognitoGroups(groups: string[]): Roles[] {
		const roles: Roles[] = [];
		for (const role in Roles) {
			const fullRoleName = AppConfig.instance.groupPrefix + Roles[role];
			if (groups.includes(fullRoleName)) {
				roles.push(Roles[role]);
			}
		}
		return roles;
	}

	private createAccessBy(roles: Roles[]): Set<AccessRights> {
		const accessRight = new Set<AccessRights>();

		for (const role of roles) {
			switch (role) {
				case Roles.VIEWER_AGENT_LIGHT:
					accessRight.add(AccessRights.REPORTING);
					accessRight.add(AccessRights.VIEW_REGISTRATION_OVERVIEW);
					accessRight.add(AccessRights.DELREG_VIEW);
					accessRight.add(AccessRights.REGVAL_VIEW);
					accessRight.add(AccessRights.MOVE_VIEW);
					break;
				case Roles.PARTNER_BACKOFFICE_LIGHT:
					accessRight.add(AccessRights.REPORTING);
					accessRight.add(AccessRights.VIEW_REGISTRATION_OVERVIEW);
					accessRight.add(AccessRights.DELREG_CREATE);
					accessRight.add(AccessRights.DELREG_VIEW);
					accessRight.add(AccessRights.DELREG_EDIT);
					accessRight.add(AccessRights.DELREG_SIGN_PAPER);
					accessRight.add(AccessRights.REGVAL_VIEW);
					accessRight.add(AccessRights.RENEWAL_VIEW);
					accessRight.add(AccessRights.MOVE_VIEW);
					break;
				case Roles.ENCODING:
					accessRight.add(AccessRights.REPORTING);
					accessRight.add(AccessRights.VIEW_REGISTRATION_OVERVIEW);
					accessRight.add(AccessRights.DELREG_CREATE);
					accessRight.add(AccessRights.DELREG_VIEW);
					accessRight.add(AccessRights.DELREG_EDIT);
					accessRight.add(AccessRights.DELREG_SIGN_PAPER);
					accessRight.add(AccessRights.REGVAL_VIEW);
					accessRight.add(AccessRights.RENEWAL_VIEW);
					accessRight.add(AccessRights.REGVAL_EDIT);
					accessRight.add(AccessRights.MOVE_CREATE);
					accessRight.add(AccessRights.MOVE_VIEW);
					accessRight.add(AccessRights.MOVE_SAVE);
					break;
				case Roles.VALIDATION:
					accessRight.add(AccessRights.REPORTING);
					accessRight.add(AccessRights.VIEW_REGISTRATION_OVERVIEW);
					accessRight.add(AccessRights.DELREG_CREATE);
					accessRight.add(AccessRights.DELREG_VIEW);
					accessRight.add(AccessRights.DELREG_EDIT);
					accessRight.add(AccessRights.DELREG_SIGN_PAPER);
					accessRight.add(AccessRights.REGVAL_VIEW);
					accessRight.add(AccessRights.RENEWAL_VIEW);
					accessRight.add(AccessRights.MOVE_CREATE);
					accessRight.add(AccessRights.MOVE_VIEW);
					accessRight.add(AccessRights.MOVE_SAVE);
					accessRight.add(AccessRights.MOVE_UPDATE);
					accessRight.add(AccessRights.MOVE_WORKING_LIST);
					break;
				case Roles.SALES_AGENT_LIGHT:
					accessRight.add(AccessRights.REPORTING);
					accessRight.add(AccessRights.VIEW_REGISTRATION_OVERVIEW);
					accessRight.add(AccessRights.DELREG_VIEW);
					accessRight.add(AccessRights.DELREG_CREATE);
					accessRight.add(AccessRights.DELREG_EDIT);
					accessRight.add(AccessRights.DELREG_SIGN_PAPER);
					accessRight.add(AccessRights.DELREG_SEND_SIGNATURE);
					accessRight.add(AccessRights.DELREG_SEND_OFFER);
					accessRight.add(AccessRights.DELREG_RESEND_OFFER);
					accessRight.add(AccessRights.DELREG_ARCHIVE);
					accessRight.add(AccessRights.DELREG_POSTPONE);
					accessRight.add(AccessRights.REGVAL_VIEW);
					accessRight.add(AccessRights.MOVE_VIEW);
					break;
				case Roles.NURSING_AGENT_LIGHT:
					accessRight.add(AccessRights.REPORTING);
					accessRight.add(AccessRights.VIEW_REGISTRATION_OVERVIEW);
					accessRight.add(AccessRights.VIEW_WORKLIST_OVERVIEW);
					accessRight.add(AccessRights.DELREG_VIEW);
					accessRight.add(AccessRights.DELREG_CREATE);
					accessRight.add(AccessRights.DELREG_EDIT);
					accessRight.add(AccessRights.DELREG_SIGN_PAPER);
					accessRight.add(AccessRights.DELREG_SEND_SIGNATURE);
					accessRight.add(AccessRights.DELREG_SEND_OFFER);
					accessRight.add(AccessRights.DELREG_RESEND_OFFER);
					accessRight.add(AccessRights.DELREG_ARCHIVE);
					accessRight.add(AccessRights.DELREG_POSTPONE);
					accessRight.add(AccessRights.REGVAL_VALIDATION_CONTROL);
					accessRight.add(AccessRights.REGVAL_VIEW);
					accessRight.add(AccessRights.REGVAL_EDIT);
					accessRight.add(AccessRights.REGVAL_RETRY);
					accessRight.add(AccessRights.REGVAL_ARCHIVE);
					accessRight.add(AccessRights.MOVE_VIEW);
					break;
				case Roles.INTERNAL_BACKOFFICE_LIGHT:
					accessRight.add(AccessRights.REPORTING);
					accessRight.add(AccessRights.VIEW_REGISTRATION_OVERVIEW);
					accessRight.add(AccessRights.DELREG_VIEW);
					accessRight.add(AccessRights.DELREG_CREATE);
					accessRight.add(AccessRights.VIEW_WORKLIST_OVERVIEW);
					accessRight.add(AccessRights.DELREG_EDIT);
					accessRight.add(AccessRights.DELREG_SIGN_PAPER);
					accessRight.add(AccessRights.DELREG_SEND_SIGNATURE);
					accessRight.add(AccessRights.REGVAL_VALIDATION_CONTROL);
					accessRight.add(AccessRights.DELREG_SEND_OFFER);
					accessRight.add(AccessRights.DELREG_RESEND_OFFER);
					accessRight.add(AccessRights.DELREG_ARCHIVE);
					accessRight.add(AccessRights.DELREG_POSTPONE);
					accessRight.add(AccessRights.REGVAL_VIEW);
					accessRight.add(AccessRights.RENEWAL_VIEW);
					accessRight.add(AccessRights.MOVE_VIEW);
					break;
				case Roles.NURSING_AGENT_EXPERT:
					accessRight.add(AccessRights.REPORTING);
					accessRight.add(AccessRights.VIEW_REGISTRATION_OVERVIEW);
					accessRight.add(AccessRights.VIEW_WORKLIST_OVERVIEW);
					accessRight.add(AccessRights.DELREG_VIEW);
					accessRight.add(AccessRights.DELREG_CREATE);
					accessRight.add(AccessRights.DELREG_EDIT);
					accessRight.add(AccessRights.DELREG_SIGN_PAPER);
					accessRight.add(AccessRights.DELREG_SEND_SIGNATURE);
					accessRight.add(AccessRights.DELREG_SEND_OFFER);
					accessRight.add(AccessRights.DELREG_RESEND_OFFER);
					accessRight.add(AccessRights.DELREG_ARCHIVE);
					accessRight.add(AccessRights.DELREG_POSTPONE);
					accessRight.add(AccessRights.REGVAL_VALIDATION_CONTROL);
					accessRight.add(AccessRights.REGVAL_VIEW);
					accessRight.add(AccessRights.REGVAL_EDIT);
					accessRight.add(AccessRights.REGVAL_RETRY);
					accessRight.add(AccessRights.REGVAL_ARCHIVE);
					accessRight.add(AccessRights.REGVAL_RETRY_ACTIVATION);
					accessRight.add(AccessRights.MOVE_VIEW);
					break;
				case Roles.INTERNAL_BACKOFFICE_EXPERT:
					accessRight.add(AccessRights.REPORTING);
					accessRight.add(AccessRights.VIEW_REGISTRATION_OVERVIEW);
					accessRight.add(AccessRights.VIEW_WORKLIST_OVERVIEW);
					accessRight.add(AccessRights.DELREG_VIEW);
					accessRight.add(AccessRights.DELREG_CREATE);
					accessRight.add(AccessRights.DELREG_EDIT);
					accessRight.add(AccessRights.DELREG_SIGN_PAPER);
					accessRight.add(AccessRights.DELREG_SEND_SIGNATURE);
					accessRight.add(AccessRights.DELREG_SEND_OFFER);
					accessRight.add(AccessRights.DELREG_RESEND_OFFER);
					accessRight.add(AccessRights.DELREG_ARCHIVE);
					accessRight.add(AccessRights.DELREG_POSTPONE);
					accessRight.add(AccessRights.REGVAL_VALIDATION_CONTROL);
					accessRight.add(AccessRights.REGVAL_VIEW);
					accessRight.add(AccessRights.REGVAL_EDIT);
					accessRight.add(AccessRights.REGVAL_RETRY);
					accessRight.add(AccessRights.REGVAL_ARCHIVE);
					accessRight.add(AccessRights.REGVAL_UNARCHIVE);
					accessRight.add(AccessRights.REGVAL_RETRY_ACTIVATION);
					accessRight.add(AccessRights.RENEWAL_VIEW);
					accessRight.add(AccessRights.MOVE_VIEW);
					break;
				case Roles.INTERAL_CREDIT_COLLECTION_EXPERT:
					accessRight.add(AccessRights.REPORTING);
					accessRight.add(AccessRights.VIEW_REGISTRATION_OVERVIEW);
					accessRight.add(AccessRights.VIEW_WORKLIST_OVERVIEW);
					accessRight.add(AccessRights.DELREG_VIEW);
					accessRight.add(AccessRights.DELREG_CREATE);
					accessRight.add(AccessRights.DELREG_EDIT);
					accessRight.add(AccessRights.DELREG_SIGN_PAPER);
					accessRight.add(AccessRights.DELREG_SEND_SIGNATURE);
					accessRight.add(AccessRights.DELREG_SEND_OFFER);
					accessRight.add(AccessRights.DELREG_RESEND_OFFER);
					accessRight.add(AccessRights.DELREG_ARCHIVE);
					accessRight.add(AccessRights.DELREG_POSTPONE);
					accessRight.add(AccessRights.REGVAL_VIEW);
					accessRight.add(AccessRights.REGVAL_EDIT);
					accessRight.add(AccessRights.REGVAL_RETRY);
					accessRight.add(AccessRights.REGVAL_VALIDATION_CONTROL);
					accessRight.add(AccessRights.REGVAL_SCREENING_UNJUSTIFIED);
					accessRight.add(AccessRights.REGVAL_CHECKFRAUD_UNJUSTIFIED);
					accessRight.add(AccessRights.REGVAL_ARCHIVE);
					accessRight.add(AccessRights.MOVE_VIEW);
					break;
				case Roles.BUSINESS_ADMIN:
				case Roles.ADMIN:
					accessRight.add(AccessRights.REPORTING);
					accessRight.add(AccessRights.VIEW_REGISTRATION_OVERVIEW);
					accessRight.add(AccessRights.VIEW_WORKLIST_OVERVIEW);
					accessRight.add(AccessRights.VIEW_BUYING_GROUP);
					accessRight.add(AccessRights.VIEW_DOCUMENTS_TEMPLATE_MANAGER);
					accessRight.add(AccessRights.VIEW_PIPELINE_DATA);
					accessRight.add(AccessRights.DELREG_VIEW);
					accessRight.add(AccessRights.DELREG_CREATE);
					accessRight.add(AccessRights.DELREG_EDIT);
					accessRight.add(AccessRights.DELREG_SIGN_PAPER);
					accessRight.add(AccessRights.DELREG_SEND_SIGNATURE);
					accessRight.add(AccessRights.DELREG_SEND_OFFER);
					accessRight.add(AccessRights.DELREG_RESEND_OFFER);
					accessRight.add(AccessRights.DELREG_ARCHIVE);
					accessRight.add(AccessRights.DELREG_POSTPONE);
					accessRight.add(AccessRights.REGVAL_VIEW);
					accessRight.add(AccessRights.REGVAL_EDIT);
					accessRight.add(AccessRights.REGVAL_RETRY);
					accessRight.add(AccessRights.REGVAL_FORCE);
					accessRight.add(AccessRights.REGVAL_VALIDATION_CONTROL);
					accessRight.add(AccessRights.REGVAL_SCREENING_UNJUSTIFIED);
					accessRight.add(AccessRights.REGVAL_CHECKFRAUD_UNJUSTIFIED);
					accessRight.add(AccessRights.REGVAL_ARCHIVE);
					accessRight.add(AccessRights.REGVAL_RETRY_ACTIVATION);
					accessRight.add(AccessRights.REGVAL_UNARCHIVE);
					accessRight.add(AccessRights.RENEWAL_VIEW);
					accessRight.add(AccessRights.MOVE_VIEW);
					accessRight.add(AccessRights.MOVE_CREATE);
					accessRight.add(AccessRights.MOVE_SAVE);
					accessRight.add(AccessRights.MOVE_UPDATE);
					accessRight.add(AccessRights.MOVE_ASSIGN);
					accessRight.add(AccessRights.MOVE_WORKING_LIST);
					break;
			}
		}

		return accessRight;
	}

	public hasAccessPromoCodeEdition(): boolean {
		return this.getUser().roles.some(
			(role) =>
				role === Roles.BUSINESS_ADMIN ||
				role === Roles.INTERNAL_BACKOFFICE_LIGHT ||
				role === Roles.PARTNER_BACKOFFICE_LIGHT ||
				role === Roles.INTERNAL_BACKOFFICE_EXPERT
		);
	}

	hasManagerRole(): boolean {
		return this.getUser().roles.some(
			(role) =>
				role === Roles.BUSINESS_ADMIN ||
				role === Roles.ADMIN ||
				role === Roles.NURSING_AGENT_EXPERT ||
				role === Roles.INTERNAL_BACKOFFICE_EXPERT ||
				role === Roles.INTERAL_CREDIT_COLLECTION_EXPERT
		);
	}
}
