import { Component } from '@angular/core';
import * as cds from '@cds/core/icon';
import '@cds/core/icon/register.js';
import { TranslateService } from '@ngx-translate/core';
import { ProductCatalogService } from './services/product-catalog.service';
import { ThemeProcessorService } from './services/theme-processor.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { RegistrationTool, Segment } from './models/constants';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	constructor(
		public translate: TranslateService,
		private productCatalogService: ProductCatalogService,
		private themeProcessor: ThemeProcessorService,
		private router: Router
	) {
		this.translate.addLangs(['nl', 'fr', 'en']);
		this.translate.setDefaultLang('fr');

		if (localStorage.getItem('language') != null) {
			this.translate.use(localStorage.getItem('language'));
		} else {
			this.translate.use('fr');
		}

		if (localStorage.getItem('market') != null && localStorage.getItem('tool') != null) {
			this.refreshTheme();
		}

		this.productCatalogService.loadBundles();

		cds.loadCoreIconSet();
		cds.loadEssentialIconSet();
		cds.loadCommerceIconSet();
		cds.loadMediaIconSet();
		cds.loadSocialIconSet();
		cds.loadTravelIconSet();
		cds.loadTextEditIconSet();
		cds.loadTechnologyIconSet();
		cds.loadChartIconSet();

		document
			.querySelector('meta[name=viewport]')
			.setAttribute('content', 'width=device-width, initial-scale=' + 1 / window.devicePixelRatio + ', shrink-to-fit=no');

		this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
			if (event.url.includes(`/${RegistrationTool.MOVE}`)) {
				localStorage.setItem('tool', RegistrationTool.MOVE);
			} else if (event.url.includes(`/${RegistrationTool.REGISTRATIONS}`)) {
				localStorage.setItem('tool', RegistrationTool.REGISTRATIONS);
			}
			if (event.url.includes(`${Segment.B2C}`)) {
				localStorage.setItem('market', Segment.B2C);
			} else if (event.url.includes(`${Segment.B2B}`)) {
				localStorage.setItem('market', Segment.B2B);
			}
			this.refreshTheme();
		});
	}

	private refreshTheme() {
		this.themeProcessor.changeTheme(
			localStorage.getItem('market').toLowerCase(),
			localStorage.getItem('tool').toLowerCase()
		);
	}
}
