<clr-header class="header-regstar">
	<div class="branding">
		<div class="title" style="flex: 1">
			<a routerLink="/">
				<span class="regstar-title">REGSTAR</span>
				<span style="color: #879092; font-family: 'Netto Offc W02 Bold', sans-serif">Belgium</span>
				<span style="color: #c6c8c8; font-size: 11px; font-style: italic">provided by</span>
				<img alt="" src="../../../../../assets/images/logo_te.svg" />
			</a>
		</div>
	</div>

	<div class="settings">
		<clr-dropdown>
			<button class="dropdown-toggle btn btn-link" clrDropdownTrigger style="color: var(--theme-color)">
				<cds-icon style="color: var(--theme-color)" shape="user"></cds-icon>
				{{ userDetails?.lastName }} {{ userDetails?.firstName }}
				<cds-icon style="color: var(--theme-color)" shape="angle" direction="down"></cds-icon>
			</button>

			<clr-dropdown-menu *clrIfOpen clrposition="bottom-right">
				<a *ngIf="hasRoles()" clrDropdownItem (click)="doOpenAndRefreshStatus()" style="color: dimgrey">
					<cds-icon style="color: dimgrey" shape="network-globe" class="has-badge is-solid"></cds-icon>
					Monitoring
				</a>

				<h4 class="dropdown-header">{{ 'menu.top.role' | translate }}</h4>
				<ng-container *ngFor="let role of userDetails?.roles">
					<div class="dropdown-item">
						<cds-icon style="color: dimgrey" shape="crown" class="is-solid"></cds-icon>
						{{ 'userProfile.roles.' + (role | lowercase | toCamel) | translate }}
					</div>
				</ng-container>

				<h4 class="dropdown-header">
					{{ 'menu.top.session.title' | translate }}
				</h4>
				<a clrDropdownItem (click)="doLogout()">
					<cds-icon style="color: dimgrey" shape="logout"></cds-icon>
					{{ 'menu.top.session.logOut' | translate }}
				</a>
				<a clrDropdownItem (click)="getJWTToken()">
					<cds-icon style="color: dimgrey" shape="clipboard"></cds-icon>
					Copy token
				</a>
				<h4 class="dropdown-header">
					{{ 'menu.top.language.label' | translate }}
				</h4>
				<clr-dropdown>
					<button class="dropdown-toggle btn btn-link" clrDropdownTrigger>
						{{ lang }}
					</button>
					<clr-dropdown-menu *clrIfOpen clrPosition="left">
						<a clrDropdownItem (click)="doChangeLang('fr')">{{ 'menu.top.language.french' | translate }}</a>
						<a clrDropdownItem (click)="doChangeLang('nl')">{{ 'menu.top.language.dutch' | translate }}</a>
						<a clrDropdownItem (click)="doChangeLang('en')">{{ 'menu.top.language.english' | translate }}</a>
					</clr-dropdown-menu>
				</clr-dropdown>
				<h4 class="dropdown-header">
					{{ 'menu.top.market.label' | translate }}
				</h4>
				<clr-dropdown>
					<button class="dropdown-toggle btn btn-link" clrDropdownTrigger>
						{{ currentMarket() }}
					</button>
					<clr-dropdown-menu *clrIfOpen clrPosition="bottom-left">
						<a clrDropdownItem (click)="doChangeMarket('b2c')">{{ 'menu.top.market.b2c' | translate }}</a>
						<a clrDropdownItem (click)="doChangeMarket('b2b')">{{ 'menu.top.market.b2b' | translate }}</a>
					</clr-dropdown-menu>
				</clr-dropdown>
			</clr-dropdown-menu>
		</clr-dropdown>
	</div>
</clr-header>

<clr-modal [(clrModalOpen)]="openMonitoring" clrModalSize="lg">
	<div class="modal-body">
		<h3>External Regstar Service health check status</h3>
		<h4 class="secondTotalTitle">Is REGSTAR working fine ?</h4>

		<div *ngIf="statusRefresh">
			<div class="loader-parent">
				<clr-spinner [clrInverse]="true">Loading ...</clr-spinner>
			</div>
		</div>

		<clr-datagrid *ngIf="!statusRefresh" [clrDgRowSelection]="false">
			<clr-dg-column>
				<ng-container>Service</ng-container>
			</clr-dg-column>
			<clr-dg-column>
				<ng-container>Status</ng-container>
			</clr-dg-column>
			<clr-dg-column>
				<ng-container>Info</ng-container>
			</clr-dg-column>

			<clr-dg-row *ngFor="let d of applicationStatus?.details" [clrDgItem]="d">
				<clr-dg-cell>
					<strong>{{ d.name.replace('-', ' ') | lowercase | titlecase }}</strong>
				</clr-dg-cell>
				<clr-dg-cell>
					<ng-container [ngSwitch]="statusColor(d)">
						<div *ngSwitchCase="'GREEN'">
							<span class="badge badge-success">&nbsp;Ok</span>
						</div>
						<div *ngSwitchCase="'ORANGE'">
							<span class="badge badge-warning">&nbsp;Not checked</span>
						</div>
						<div *ngSwitchCase="'RED'">
							<span class="badge badge-danger">&nbsp;Problem</span>
						</div>
					</ng-container>
				</clr-dg-cell>
				<clr-dg-cell>
					<div *ngIf="!d.error" class="alert alert-info" role="alert">
						<div class="alert-items">
							<div class="alert-item static">
								<div class="alert-icon-wrapper">
									<cds-icon class="alert-icon" shape="info-circle"></cds-icon>
								</div>
								<span class="alert-text">
									{{ d.description }}
								</span>
							</div>
						</div>
					</div>

					<div *ngIf="d.error" class="alert alert-danger" role="alert">
						<div class="alert-items">
							<div class="alert-item static">
								<div class="alert-icon-wrapper">
									<cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
								</div>
								<span class="alert-text">
									{{ d.error }}
								</span>
							</div>
						</div>
					</div>
				</clr-dg-cell>
			</clr-dg-row>
		</clr-datagrid>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="openMonitoring = false">close</button>
	</div>
</clr-modal>
